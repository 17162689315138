import React from 'react';
import { Link, Redirect,useHistory  } from "react-router-dom";
import Apiurl,{site_url,Admin} from '../../Apiurl'; 
import ReactHtmlParser from 'react-html-parser';
import {cosmaticAsset} from '../../constants/common'
import {ValidationMsg} from'../../constants/validationmsg';
import{hasNull,isRequired} from '../../validation';
import ThumbnailImage_prod from '../../../images/downstream-defender_prod.jpg';


class Adminproductadd extends React.Component{
		constructor(props){
			super(props);
			this.state={
				smallLoader:false,
				newproductimageid:null,
				uploadedproductimage:'',
				imageFormateState:false,
				productname:false,
				description:false,
				productsheettitle:false,
				fid:'',
				fileuploadedname:'',
				doucmentformatestate:false
			}
			this.update_product_image=this.update_product_image.bind(this);
			this.upload_product_document=this.upload_product_document.bind(this);
			this.productsheetselection=this.productsheetselection.bind(this);
		}

		productsheetselection=(e)=>{
			e.preventDefault();
			hasNull(e.target.value) ? this.setState({productsheettitle:true}): this.setState({productsheettitle:false});
		}


		upload_product_document=(e)=>{
			var fullPath = e.target.files[0];
	      var exactfile=e.target.value;
	      var filename='';
         if (exactfile) {
             var startIndex = (exactfile.indexOf('\\') >= 0 ? exactfile.lastIndexOf('\\') : exactfile.lastIndexOf('/'));
             filename = exactfile.substring(startIndex);
             if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
             	let extension=filename.split(".")[1];
             	
             	console.log(extension);
                 filename = document.querySelector("#product-title").value!=='' ? document.querySelector("#product-title").value+"."+extension:filename.substring(1);
                 this.setState({fileuploadedname:filename})
             }
             console.log(filename);
             if(filename.includes(".docx") || filename.includes(".pptx") || filename.includes(".ppt")|| filename.includes(".doc")|| filename.includes(".pdf")|| filename.includes(".txt") || filename.includes(".csv")){
               this.setState({doucmentformatestate:false})
               var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/octet-stream");
                  myHeaders.append("X-CSRF-Token", localStorage.getItem("access-token"));
                  myHeaders.append("Content-Disposition", "file;filename=\""+filename+"\"");
                  myHeaders.append("Authorization", "Basic "+localStorage.getItem("basic-auth"));
                  var file = filename;
                  console.log(file);
                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: fullPath,
                  };
                  fetch(Admin.adminproductAdddocument.url,requestOptions)
                  .then(res=>{return res.json()})
                  .then(data=>{
                     console.log(data);
                     this.setState({fid:data.fid[0].value});
                  })
		      }else{
		         this.setState({doucmentformatestate:true})   
		     }
  		}
		}


		update_product_image=(e)=>{
		console.log(e.target.value)
		this.setState({smallLoader:true});
		var fullPath = e.target.files[0];
		var exactfile=e.target.value;
		var filename='';
			if (exactfile) {
			    var startIndex = (exactfile.indexOf('\\') >= 0 ? exactfile.lastIndexOf('\\') : exactfile.lastIndexOf('/'));
			    filename = exactfile.substring(startIndex);
			    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
			        filename = filename.substring(1);
			    }
		}

		if(filename.includes(".jpg") || filename.includes(".gif") || filename.includes(".png")){
				this.setState({imageFormateState:false})	
				var myHeaders = new Headers();
				myHeaders.append("Content-Type", "application/octet-stream");
				myHeaders.append("X-CSRF-Token", localStorage.getItem("access-token"));
				myHeaders.append("Content-Disposition", "file;filename=\""+filename+"\"");
				myHeaders.append("Authorization", "Basic "+localStorage.getItem("basic-auth"));
				var file = filename;
				console.log(file);
				var requestOptions = {
				  method: 'POST',
				  headers: myHeaders,
				  body: fullPath,
				};
				fetch(Admin.adminproductAddimage.url,requestOptions)
				.then(res=>{return res.json()})
				.then(data=>{console.log(data);
					this.setState({smallLoader:false,newproductimageid:data.fid[0]['value'],uploadedproductimage:site_url+data.uri[0].url})
					console.log(this.state.newproductimageid);
					console.log(this.state.uploadedproductimage);
				})
	  }else{
	  	this.setState({smallLoader:false,imageFormateState:true})	
	  }
	}

	submitProduct=(e)=>{
		e.preventDefault();
		let options={
		   	"title":[{value:document.querySelector("#title").value}],
	        "type":[{target_id:"products"}],        
	        "field_product_description":[{value:document.querySelector("#description").value}],
	        "field_product_image":[{target_id:document.querySelector("#product-image").getAttribute("data-id")}],
	        //"field_product_category":[{target_id:}],
	        "field_product_sheet_title":[{value:document.querySelector("#product-title").value}],
	        "field_product_document":[{target_id:document.querySelector(".document-item-product").getAttribute("get-id")}]
		}

		console.log(options);
		return false;
	}


		render(){
			return(
					<div className="d-flex flex-wrap admin-products-add">
					<form onSubmit={this.submitProduct}>
						<div className="product-add-form">
							<div className="form-group d-flex flex-wrap align-center">
										<label>Product name*</label>
										<div className="input-box">
											<input type="text" name="Product name" id="title" placeholder="Product name" onBlur={(e)=>hasNull(e.target.value) ? this.setState({productname:true}): this.setState({productname:false})}/>
											{this.state.productname ? ValidationMsg.common.default.productnamefield : ''}
										</div>
									</div>
							<div className="form-group d-flex flex-wrap align-center">
										<label>Description*</label>
										<div className="input-box">
											<input type="text" name="Description" id="description" placeholder="Description" onBlur={(e)=>hasNull(e.target.value) ? this.setState({description:true}): this.setState({description:false})}/>
											{this.state.description ? ValidationMsg.common.default.productdescriptionfield : ''}
										</div>
									</div>
							<div className="upload-thumbnail d-flex flex-wrap">
									<div className="upload-btn-block">
						            <span className='suggestion-file-name'>JPG, GIF or PNG. Max size of 1mb.</span>

										<div className="upload-btn-wrapper">
											<input type="file" name="Upload photo" id="product-image" onChange={this.update_product_image} data-id={this.state.newproductimageid}/>
											<button className="btn wide common-btn-blue">
											<span>Upload photo</span></button>
										</div>
										{this.state.imageFormateState ? ValidationMsg.common.default.imageformate : ''}
									</div>
				
								<div className="upload-thumbnail-right">
									{this.state.smallLoader ? 
										<div className="loader"></div>
						            	:
										<div className="upload-thumbnail-img bg-cover" style={{backgroundImage: `url(${this.state.uploadedproductimage!=='' ? this.state.uploadedproductimage : ThumbnailImage_prod})`}}>
										</div>	
								    }
								</div>
							</div>
						
						<div className="product-sheet-title">

						<div className="form-group d-flex flex-wrap align-center">
										<label>Product sheet title*</label>
										<div className="input-box">
											<input type="text" name="Product name" id="product-title" placeholder="Product sheet title" onBlur={this.productsheetselection} />
											{this.state.productsheettitle ? ValidationMsg.common.default.productsheettitlefield : ''}
										</div>
						</div>
						
							<div className="upload-prod-sheet">
							<div className="upload-btn-block">
				            <span className='suggestion-file-name'>txt, pdf, doc, ppt, pptx, docx, csv.</span>

											<div className="upload-btn-wrapper">
												<input type="file" name="Upload product sheet"  id="product-shet-upload"onChange={this.upload_product_document}/>
												<button className="btn wide common-btn-blue">
												<span>Upload product sheet</span></button>
											</div>
											<span className='document-item document-item-product' get-id={this.state.fid}>{this.state.fileuploadedname}</span>
				                  			{this.state.doucmentformatestate ? ValidationMsg.common.default.imageformate : ''}
										</div>
							</div>
						</div>
						<div className="btn-block">
							<button className="btn wide common-btn-blue">
							<span>{!this.props.readmode ? "Update Product":"Add Product"}</span></button>
							</div>
						</div>
						</form>	
					</div>		
				)
		}

}

export default Adminproductadd;